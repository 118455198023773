<template>
    <div>
        <ValidationObserver ref="form" v-slot="{failed,valid}">
            <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">
                <h2 class="titulo left">Datos Familiares y amigos</h2>

                <div class="contenedor-input">
                    <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <autocomplete :class="classes" :name="'personal_type'" :label="'Relación'"
                            :type="'personal_type'" v-model="data.type" :idkey="'id'"></autocomplete>
                        <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>



                <div class="contenedor-input">
                    <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <label for="comment">Indica nombre y apellidos de tu familiar o amigo en la UFV*</label>
                        <textarea name="comment" id="comment" v-model="data.comment" :fill="!!data.comment"
                            :class="classes"></textarea>
                        <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>


                <div class="full-width row-center">
                    <button class="boton-azul">Siguiente</button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>


<script>


    import { mapGetters,mapActions } from 'vuex'

    export default {
        name: 'internacional',
        data: () => ({
            data: {
                type: '',
                comment: ''
            }
        }),
        methods: {
            ...mapActions(['saveFamiliar']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;

                        console.log(this.data);

                        this.saveFamiliar(this.data).then(r => {
                            if(r){
                                this.$emit('next');
                            }
                        })
                    }
                });
            },
        },
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        }
    }
</script>
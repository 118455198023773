<template>
    <div class="wrapper-registro cover-login">
        <div class="popup">


            <template v-if="selected == 'intro'">

                <div class="paso">

                    <h2 class="titulo left">Completa tu perfil</h2>

                    <p><strong>Indícanos cuál es tu relación con la UFV</strong></p>

                    <div class="toggle">
                        <input type="checkbox" id="elu" v-model="tips.elu">
                        <label for="elu">Soy egresado de la Escuela de Liderazgo Universitario (ELU) de la
                            UFV</label>
                    </div>

                    <div class="toggle">
                        <input type="checkbox" id="egresadoufv" v-model="tips.egresado">
                        <label for="egresadoufv">Soy egresado de la UFV</label>
                    </div>

                    <div class="toggle">
                        <input type="checkbox" id="familiar" v-model="tips.familiar">
                        <label for="familiar">Soy familiar o amigo de un alumni de la UFV</label>
                    </div>

                    <div class="toggle">
                        <input type="checkbox" id="estudiante-internacional" v-model="tips.internacional">
                        <label for="estudiante-internacional">Soy estudiante internacional</label>
                    </div>


                    <div class="full-width row-center">
                        <button class="boton-azul" @click="next">Siguiente</button>
                    </div>

                </div>

            </template>


            <!-- INTERNACIONAL -->
            <template v-if="selected == 'internacional'">
                <internacional @setData="sendData"></internacional>
            </template>



            <!-- EGRESADO -->
            <academico v-if="selected == 'egresado'" :showedit="true" :notelu="true" :onlyufv="true" @refresh="next"></academico>

            <!-- ELU -->

            <template v-if="selected == 'elu'">

                <academico v-if="!showotroselu" :elu="true" :onlyufv="eluinufv" :showedit="true" @refresh="showotroselu = true"></academico>

                <template v-if="showotroselu">

                    <div class="contenedor-input half row-start fww">
                        <h5>Miembro clavis</h5>
                        <input type="radio" :value="1" name="clavis" id="clavis_si" v-model="data.key_member">
                        <label for="clavis_si">Sí</label>
                        <input type="radio" :value="0" name="clavis" id="clavis_no" v-model="data.key_member">
                        <label for="clavis_no">No</label>
                    </div>

                    <div class="contenedor-input row-start fww">
                        <h5>Si quieres que otros Elumni contacten contigo indícanos vías de contacto disponibles: </h5>
                        <div class="full" v-for="(el,key) in dominios.contactar" :key="key">
                            <input type="radio" :value="key" name="contactar" :id="'contactar'+key"
                                v-model="data.allow_others">
                            <label :for="'contactar'+key">{{el}}</label>
                        </div>
                    </div>

                    <div class="contenedor-input">
                        
                            <input autocomplete="off" type="text" name="direccion" placeholder="Indícanos cualquier otra beca, premios, reconocimientos, méritos académicos u observaciones"
                                v-model="data.awards">
                        
                        
                    </div>

                    <div class="full-width row-center">
                        <button class="boton-azul" @click="saveData">Guardar</button>
                    </div>

                </template>

            </template>

            <template v-if="selected == 'familiar'">
                <familiares @next="next"></familiares>
            </template>

            <template v-if="selected == 'final'">
                <h2>Ya hemos terminado. ¡Muchas gracias!</h2>
                <router-link class="boton-azul" to="/home">Finalizar</router-link>
            </template>





        </div>
    </div>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';


    import internacional from '@/views/completa-perfil/internacional'
    import familiares from '@/views/completa-perfil/familiares'


    import academico from '@/components/academico-el';

    export default {
        name: 'completa-tu-perfil',
        props : ['inactivar'],
        mounted() {
            this.setData();
        },
        data: () => ({
            paso: 1,
            selected: 'intro',
            tips: {
                intro: true,
                familiar: false,
                egresado: false,
                elu: false,
                internacional: false,
                final : true
            },
            showotroselu: false,
            eluinufv : false,
            data: {},
            loading: false
        }),
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            }),
            pasos() {
                return Object.entries(this.tips).filter(e => e[1]);
            }
        },
        methods: {
            ...mapActions(['getAlumni', 'saveAlumni']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;

                    }
                });
            },
            setData() {
                this.getAlumni().then(r => {
                    this.data = r;
                });
            },
            next() {

                if(this.tips.elu && this.tips.egresado){
                    this.tips.egresado = false;
                    this.eluinufv = true;
                }

                let onlytrue = Object.entries(this.tips).filter(e => e[1]);
                onlytrue = onlytrue.map(e => e[0]);
                let pos = onlytrue.findIndex(e => e == this.selected);
                if (pos + 1 <= onlytrue.length - 1) {
                    this.selected = onlytrue[pos + 1]
                }

                if(this.tips.internacional){
                    this.setinternacional();
                }

                if(this.selected == 'final'){
                    this.completePerfil();
                }
            },
            sendData(value) {
                for (let i in value) {
                    this.data[i] = value[i];

                    this.saveAlumni(this.data).then(r => {
                        if(r.status){
                            this.next();
                        }
                    })

                }
            },
            saveData() {
                this.saveAlumni(this.data).then(r => {
                    if(r.status){
                        if(this.selected != 'final'){
                            this.next();
                        }
                    }
                })
            },
            setinternacional(){
                this.data.internacional_bowl = true;
                this.saveAlumni(this.data);
            },
            completePerfil(){
                this.data.full_profile = 1;
                this.saveAlumni(this.data);
            }
        },

        components: {
            'internacional': internacional,
            'familiares': familiares,
            'academico': academico
        }
    }
</script>
<template>
    <div class="microform" :edit="edit">

        <template v-if="!edit && data">
            <h3>{{dominios.nivel[data.level]}}</h3>
            <h2>{{data.study.name}}, {{data.university.name}}, {{data.year}}</h2>
            <p v-if="data.elu_year">Promoción ELU: {{data.elu_year}} </p>
        </template>

        <a class="editar" v-if="data.dataId" @click="edit = true">editar</a>
        <a class="eliminar" v-if="data.dataId" @click="showerase = true">Eliminar</a>


        <template v-if="edit">

            <ValidationObserver ref="form" v-slot="{failed,valid}">

                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">

                    <template v-if="!notelu">



                        <div class="contenedor-input row-start only-one-option" v-if="!elu">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input type="checkbox" :value="true" name="haselu" :id="'haselu-'+pos" v-model="data.haselu">
                                <label :class="classes" :for="'haselu-'+pos">Soy Egresado de la Escuela de Liderazgo
                                    Universitario (ELU)</label>
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input" v-if="data.haselu">
                            <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <year-datepicker :class="classes" :label="'Año de promoción ELU'" :minpage="new Date()"
                                    v-model="data.elu_year" :name="'elu_year'">
                                </year-datepicker>
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <figure v-if="data.haselu">
                            Selecciona estudios de acceso a ELU
                        </figure>

                    </template>

                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="">Nivel</label>
                            <select id="nivel" name="nivel" v-model="data.level" class="pretty" :class="classes">
                                <option name="myBrowser" placeholder="" value=""></option>
                                <option :key="key" v-for="(nivel,key) in dominios.nivel" name="myBrowser" :value="key">
                                    {{nivel}}</option>
                            </select>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <autocomplete :class="classes" :name="'study'" :label="'Titulación'" :type="'study'"
                                v-model="data.study" :idkey="'id'"></autocomplete>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>


                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <autocomplete :class="classes" :name="'university'" :label="'Universidad'"
                                :type="(!ownonlyufv) ? 'university' : 'ufv'" v-model="data.university" :idkey="'id'" ref="university"></autocomplete>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>



                    <div class="contenedor-input half">
                        <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <year-datepicker :class="classes" :label="'Año de promoción'" :minpage="new Date()"
                                v-model="data.year" :name="'year'">
                            </year-datepicker>
                            <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>



                    <div class="full-width row-center">
                        <button class="boton-azul" :loading="loading">Guardar</button>
                    </div>


                </form>

            </ValidationObserver>

        </template>



        <popup-confirmacion v-if="showerase" :loading="loadingerase" :how="'borraracademico'" @accept="requestErase"
            @cancel="showerase = false" :isnegative="true">
        </popup-confirmacion>


    </div>
</template>


<script>

    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'academico',
        props: ['values', 'showedit', 'notelu', 'elu', 'onlyufv','pos'],
        mounted() {
            if (this.values) {
                this.data = this.values;
                if (this.values.elu_year) {
                    this.data.haselu = true;
                }
            }

            if (this.elu) {
                this.data.haselu = true;
            }
            this.ownonlyufv = this.onlyufv;

            this.edit = this.showedit;
        },
        data: () => ({
            data: {
                university: '',
                study: '',
                level: '',
                elu_year: '',
                year: '',
                haselu: false
            },
            edit: false,
            loading: false,
            loadingerase: false,
            showerase: false,
            ownonlyufv : false
        }),
        methods: {
            ...mapActions(['deleteAcademico', 'saveAcademico']),
            requestErase() {
                let data = [this.data.dataId];
                data = { dataId: data }
                this.deleteAcademico(data).then(r => {
                    if (r) {
                        this.$emit('refresh');
                        this.showerase = false;
                    }
                })
            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;


                        let data = JSON.parse(JSON.stringify(this.data))

                        if (typeof data.study == 'object') {
                            data.study = data.study_id;
                        }

                        if (typeof data.university == 'object') {
                            data.university = data.university_id;
                        }

                        this.saveAcademico(data).then(r => {
                            this.loading = false;
                            if (r) {
                                this.edit = false;
                                this.$emit('refresh')
                            }
                        })
                    }
                });
            },
            cancel() {
                this.$emit('cancel');
                this.edit = false;
            }
        },
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        },
        watch : {
            'data.haselu'(){
                    this.ownonlyufv = !this.data.haselu;
                    if(this.data.haselu && !this.data.dataId){
                        this.data.university = '';
                        this.$refs.university.erase();
                    }
                
            }
        }


    }
</script>
<template>
    <div>
        <ValidationObserver ref="form" v-slot="{failed,valid}">
            <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">
                <h2 class="titulo left">Datos Alumni Internacional</h2>

                <div class="contenedor-input row-start fww">
                    <h5>¿Cuál es tu relación con la UFV?</h5>
                    <div class="full" v-for="(n, key) in dominios.relacionufv" :key="key">
                        <input type="radio" :value="key" name="international_relation" :id="'relacion-'+key"
                            v-model="data.international_relation">
                        <label :for="'relacion-'+key">{{n}}</label>
                    </div>
                </div>

                <div class="contenedor-input">
                    <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <year-datepicker :class="classes" :label="'¿Cuándo participaste o hiciste tu intercambio con la UFV?'" :minpage="new Date()"
                            v-model="data.international_year" :name="'international_year'">
                        </year-datepicker>
                        <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>

                <div class="contenedor-input">
                    <ValidationProvider rules="required" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                        <input autocomplete="off" type="text" name="buddy" placeholder="Indícanos el nombre de tu buddy en la UFV"
                            v-model="data.internaltional_buddy" :class="classes">
                        <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>



                <div class="full-width row-center">
                    <button class="boton-azul">Siguiente</button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>


<script>


    import { mapGetters } from 'vuex'

    export default {
        name: 'internacional',
        data: () => ({
            data: {
                international_relation: '',
                internaltional_buddy : '',
                international_year : ''
            }
        }),
        methods: {
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.$emit('setData', this.data)
                    }
                });
            },
        },
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        }
    }
</script>